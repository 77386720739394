import React from "react";
import "./App.css";
import Nav from "./components/home/nav";
import Home from "./components/home/home";
import Experience from "./components/experience/expPage";
import ProjectPage from "./components/projects/projectPage";
import Footer from "./components/footer/footer";

function App() {
    return (
        <div className="App">
            <Nav />
            <Home />
            <Experience />
            <ProjectPage />
            <Footer />
        </div>
    );
}

export default App;
