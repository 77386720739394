import { Container } from "@mui/system";
import Experience from "./experience.jsx";
import "./expPage.css";

const ExperiencePage = () => {
    const jobDataList = [
        {
            logo: "TDBank.png",
            company: "TD Securities",
            position: "Software Engineer Intern",
            date: "May 2023 - Present",
            location: "Toronto, ON, Canada",
            details: [
                "Low Latency ETrading Team - Global Markets FICC",
                "Working on software behind institutional foreign exchange, a low latency trading platform with Java, Spring Boot, and React.",
            ],
        },
        {
            logo: "TDBank.png",
            company: "TD Securities",
            position: "Software Engineer Intern",
            date: "Jan 2023 - May 2023",
            location: "Toronto, ON, Canada",
            details: [
                "Transparency Trader Team - Global Markets FICC",
                "Worked on an intraday risk and P&L platform built with React, Redux and Java servicing front office trading desks for real-time position management.",
            ],
        },
        {
            logo: "TDBank.png",
            company: "TD Securities",
            position: "Software Engineer Intern",
            date: "May 2021 - Dec 2022",
            location: "Toronto, ON, Canada",
            details: [
                "Virtual Corporate Accounts Team - Global Transaction Banking",
                "Designed and built ISO transaction microservice to fetch virtual account transaction histories using Java, Spring Boot, and IBM MQ.",
            ],
        },
        {
            logo: "Krates.png",
            company: "Krates",
            position: "Software Engineer Contract",
            date: "Oct 2021 - Feb 2022",
            location: "Harrisburg, PA, USA",
            details: [
                // "Integrated OAuth2 into Krates’ shipping services platform, allowing Google sign-in on top of the existing user/pass.",
                // "Implemented Stripe payment processing. Built, and maintained 100% of the order fulfillment flow.",
                // "Collaborated with UI/UX team brought Figma mocks to life in Vue.js.",
                "Shipping SAAS Platform, Firebase OAuth2 Integration, Stripe Payment Processing, Frontend work with Vue.js",
            ],
        },
        {
            logo: "BlackBerry.jpeg",
            company: "BlackBerry",
            position: "Software Engineer Intern",
            date: "Aug 2021 - Dec 2021 ",
            location: "Ottawa, ON, Canada",
            details: [
                "Enterprise Identity Team",
                "Improved security and reliability of Enterprise Identity systems by engineering patches to resolve security issues and bugs with Java/JavaScript.",
            ],
        },
        {
            logo: "GCTO.jpeg",
            company: "Global Creative Technology",
            position: "Software Engineer Intern",
            date: "May 2021 - August 2021",
            location: "Toronto, ON",
            details: [
                // "Kickstarted a MVP securing $60K in funding with 3 engineers using Typescript, Express.js, and Vue.js",
                // "Facilitated secure transactions by developing a smart contract for the marketplace and a MetaMask OAuth2 flow.",
                // "Streamlined the checkout process by automating order fulfillment on validating Stripe payment webhook.",
                // "Automated the CI/CD process for UI, end-to-end tests, and deployment to Google Cloud with Jest, and Selenium.",
                "Kickstarted a MVP securing $60K in funding with 3 engineers using Typescript, Express.js, and Vue.js",
                "Facilitated secure transactions by developing a smart contract for the marketplace and a MetaMask OAuth2 flow.",
            ],
        },
    ];

    return (
        <Container
            id="experience"
            className="exp-page"
            style={{
                display: "flex",
                justifyContent: "center",
            }}
        >
            <h1 style={{ marginBottom: "10vh", marginTop: "10vh" }}>
                Experience
            </h1>
            {jobDataList.map((jobData) => {
                return <Experience jobInfo={jobData} />;
            })}
        </Container>
    );
};

export default ExperiencePage;
