import { Container } from "@mui/system";
import Project from "./project";
import "./projectPage.css";

const ProjectPage = () => {
    const projectDatalList = [
        {
            logo: "Terminal.png",
            title: "SolanaBot",
            details: [
                "A software automating the sending of blockchain transactions to acquire NFTs on the Solana blockchain.",
                "Licensed and purchased by 40+ paid users, has sent over 1750+ successful Solana blockchain transactions.",
                "Designed and implemented a SQL database to map user, wallets, licenses, subscription information.",
                "Deployed a React.js site to allow users to purchase SolanaBot through a chrome wallet.",
            ],
        },
        {
            logo: "AmzGen.jpg",
            title: "AMZ Gen",
            details: [
                "A CLI tool automating account creation and reinstatement for Amazon and Outlook.",
                "Used and purchased by over 75+ users and has logged and generated over 3000+ new accounts.",
                "Obfuscated code & packaged software into an executable to be distributed to users for patches and updates.",
            ],
        },
        {
            logo: "TorontoCypress.png",
            title: "Toronto Cypress",
            details: [
                "Collaborated with a team of developers in delivering an app to report and track incidents in the city.",
                "Built with React.js, Redux.js, GCP Cloud Functions, Firebase Authentication and Firestore cloud database.",
            ],
        },
        {
            logo: "Stashio.png",
            title: "Stashed.io",
            details: [
                "Engineered a web app to track and analyze the inventory + sales of a business.",
                "OAuth2 users verified accounts, linked to a MongoDB database.",
                "Modelled user inptu data graphically.",
            ],
        },
        {
            logo: "Discord.jpg",
            title: "Discord Automation",
            details: [
                "Automation of information: Synced program to login to 10+ discord accounts pulling information of different servers through sockets, outputs all information into a single server at a lancency of 150ms.",
                "OCR: Bot identifying text within pictures, and outputting the text as human readable format",
                "Gifts: Parsed messages from socket with regex to identify gifts, redeemed through reversed engineered API call (No public API).",
                "Invitations: Parsed messages from socket for limited invites, automatically joined servers.",
            ],
        },
    ];

    return (
        <Container
            id="projects"
            className="project-page"
            style={{
                display: "flex",
                justifyContent: "center",
            }}
        >
            <h1 style={{ marginBottom: "10vh", marginTop: "10vh" }}>
                Projects
            </h1>
            {projectDatalList.map((project) => {
                return <Project project={project}></Project>;
            })}
        </Container>
    );
};

export default ProjectPage;
