import LocalCafeIcon from "@mui/icons-material/LocalCafe";
import "./footer.css";

const Footer = () => {
    return (
        <div
            className="footer"
            style={{ display: "flex", justifyContent: "center" }}
        >
            <strong>Created by Coffee + Rocky&ensp; </strong>
            <LocalCafeIcon size="sm" style={{}} />
        </div>
    );
};

export default Footer;
