import IconButton from "@mui/material/Button";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";
import { Container } from "@mui/material";
import { Grid } from "@mui/material";
import "./nav.css";
import "./home.css";

const Home = () => {
    return (
        <Container
            id="home"
            className="home-page"
            style={{
                display: "flex",
            }}
        >
            <Grid
                container
                rowSpacing={{ xs: 4, md: 0 }}
                columnSpacing={{ xs: 0, md: 1 }}
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Grid
                    item
                    sm={0}
                    md={4}
                    style={{
                        display: "flex",
                    }}
                    sx={{ display: { xs: "none", md: "block" } }}
                    justifyContent={{ xs: "center", md: "between" }}
                >
                    <img
                        id="profileImg"
                        src={require("../../assets/rockybitmoji.png")}
                        alt="Logo"
                        style={{
                            borderRadius: 40,
                            maxHeight: "376px",
                            maxWidth: "335px",
                            height: "auto",
                            width: "100%",
                        }}

                        // className="drop-shadow"
                    />
                </Grid>

                <Grid item sm={12} md={8}>
                    <div
                        id="bio"
                        style={{
                            display: "inline-flex",
                            flexDirection: "column",
                            alignItems: "right",
                            textAlign: "left",
                        }}
                    >
                        <div
                            style={{
                                display: "inline-flex",
                                flexDirection: "column",
                                marginBottom: "3vh",
                            }}
                        >
                            <span>Hey there! I'm</span>
                            <h1>Rocky Kuang</h1>
                            <span>Exploring New Grad Opportunities</span>
                            <span>
                                Fourth Year Computer Science Student @ TMU
                            </span>
                            <span>Powered by coffee. </span>
                        </div>

                        <div>
                            <IconButton
                                size="large"
                                className="contact-button"
                                href="https://www.linkedin.com/in/rocky-kuang/"
                                target="_blank"
                            >
                                <LinkedInIcon fontSize="large" />
                            </IconButton>

                            <IconButton
                                size="large"
                                className="contact-button"
                                href="https://github.com/RockyLogic/"
                                target="_blank"
                            >
                                <GitHubIcon fontSize="large" />
                            </IconButton>

                            <IconButton
                                size="large"
                                className="contact-button"
                                href="mailto: hkuang@ryerson.ca"
                                target="_blank"
                            >
                                <EmailIcon fontSize="large" />
                            </IconButton>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Home;
