import { Grid } from "@mui/material";
import "./project.css";

const Project = (props) => {
    const project = props.project;
    return (
        <Grid
            container
            className="project"
            spacing={{ xs: 2 }}
            style={{
                display: "flex",
                justifyContent: "center",
                marginLeft: "0",
            }}
        >
            <Grid item xs={12} md={6}>
                <img
                    src={require(`../../assets/${project.logo}`)}
                    alt="Logo"
                    className="project-logo"
                />
            </Grid>

            <Grid item xs={12} md={5}>
                <h2 className="job-title">{project.title}</h2>
                {project.details.map((detail) => {
                    return <div className="project-detail">{detail}</div>;
                })}
            </Grid>
        </Grid>
    );
};

export default Project;
